import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes

const useInactivityLogout = () => {
  const auth = useAuth();
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Reset the inactivity timer
    const resetTimer = () => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        // Log out the user after inactivity timeout
        auth.signoutRedirect();
      }, INACTIVITY_TIMEOUT);
    };
    // List of events to detect user activity
    const events = ['keydown', 'mousedown', 'mousemove', 'touchstart', 'scroll'];

    // Attach event listeners
    events.forEach(event => document.addEventListener(event, resetTimer));

    // Start the timer on mount
    resetTimer();

    // Cleanup event listeners and timer on component unmount
    return () => {
      if (timer.current) clearTimeout(timer.current);
      events.forEach(event => document.removeEventListener(event, resetTimer));
    };
  }, []);
};

export default useInactivityLogout;