import React, { createContext, useContext, useState, ReactNode } from 'react';

type BreadcrumbContextType = {
  breadcrumbTitle: string ;
  dashboardIcon: string | null;
  setBreadcrumbTitle: React.Dispatch<React.SetStateAction<string>>;
  setDashboardIcon: React.Dispatch<React.SetStateAction<string | null>>
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export function BreadcrumbProvider({ children }: { children: ReactNode }) {
  const [breadcrumbTitle, setBreadcrumbTitle] = useState<string>('');
  const [dashboardIcon, setDashboardIcon] = useState<string | null>(null);

  return (
    <BreadcrumbContext.Provider value={{
      breadcrumbTitle,
      dashboardIcon,
      setBreadcrumbTitle,
      setDashboardIcon
      }}>
      {children}
    </BreadcrumbContext.Provider>
  );
}

export function useBreadcrumb() {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }
  return context;
}
