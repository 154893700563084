import '../../i18n/config';
import { useTranslation } from 'react-i18next';
import Widget from '../dashboard/widgets/Widget';
import '../dashboard/Dashboard.scss';

const NOTIFICATIONS_SKELETON_HEIGHT = 800;
const CONTACT_SKELETON_HEIGHT = 430;
const ONGOING_SKELETON_HEIGHT = 560;
const FREQUENTLY_VISITED_SKELETON_HEIGHT = 528;
const FINANCE_SKELETON_HEIGHT = 330;
const CALENDAR_SKELETON_HEIGHT = 460;
const USERS_OVERVIEW_SKELETON_HEIGHT = 480;
const SUPPORT_CENTER_SKELETON_HEIGHT = 368;
const SOCIAL_MEDIA_SKELETON_HEIGHT = 800;

function SkeletonLayout() {
  const { t } = useTranslation();

  return (
    <main className="bc-container">
      <section className="bc-step no-background">
        <div className="skeleton-title bc-skeleton"></div>
        <div className="skeleton-select bc-skeleton"></div>
      </section>

      <section className="bc-cols first-level">
        <div className="bc-col bc-is-12 bc-is-6-md bc-is-4-xl">
          {/* Notifications */}
          <Widget title={t('dashboard.widgets.notifications.title')} isSkeleton={true} height={NOTIFICATIONS_SKELETON_HEIGHT}>
            <></>
          </Widget>
          {/* Contact us */}
          <Widget title={t('dashboard.widgets.contact_us.title')} isSkeleton={true} height={CONTACT_SKELETON_HEIGHT}>
            <></>
          </Widget>
          {/* Ongoing Services */}
          <Widget title={t('dashboard.widgets.service_orders.title')} isSkeleton={true} height={ONGOING_SKELETON_HEIGHT}>
            <></>
          </Widget>
        </div>
        <div className="bc-col bc-is-12 bc-is-6-md bc-is-8-xl">
          <div className="bc-cols second-level">
            <div className="bc-col bc-is-12 bc-is-7-xl">
              {/* Frequently visited */}
              <Widget title={t('dashboard.widgets.frequently_visited.title')} isSkeleton={true} height={FREQUENTLY_VISITED_SKELETON_HEIGHT}>
                <></>
              </Widget>
              {/* Finance */}
              <Widget title={t('dashboard.widgets.finance.title')} isSkeleton={true} height={FINANCE_SKELETON_HEIGHT}>
                <></>
              </Widget>
              {/* Calendar */}
              <Widget title={t('dashboard.widgets.calendar.title')} isSkeleton={true} height={CALENDAR_SKELETON_HEIGHT}>
                <></>
              </Widget>
              {/* Users Overview */}
              <Widget title={t('dashboard.widgets.users_overview.title')} isSkeleton={true} height={USERS_OVERVIEW_SKELETON_HEIGHT}>
                <></>
              </Widget>
            </div>
            <div className="bc-col bc-is-12 bc-is-5-xl">
              {/* Support Center */}
              <Widget title={t('dashboard.widgets.support_center.title')} isSkeleton={true} height={SUPPORT_CENTER_SKELETON_HEIGHT}>
                <></>
              </Widget>
              {/* Social Media */}
              <Widget title={t('dashboard.widgets.social_media.title')} isSkeleton={true} height={SOCIAL_MEDIA_SKELETON_HEIGHT}>
                <></>
              </Widget>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SkeletonLayout;
