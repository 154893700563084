import '../../i18n/config';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '../../context/BreadcrumbContext';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumb.scss'
import {useEffect, useState} from "react";
import useLocalStorage from '../../hooks/useLocalStorage';
import type {MenuItem, Navigation} from "../header/HeaderBottomMenu";
import React from 'react';

function Breadcrumb() {
  const { t } = useTranslation();
  const { breadcrumbTitle } = useBreadcrumb();
    const [breadcrumbPath, setBreadcrumbPath] = useState<string[]>([]);
  const isDashboard = breadcrumbTitle === t('dashboard.breadcrumb');
    const { data } = useLocalStorage<Navigation>('navigation');

    // list of pages to look up in menu items
    const pages= [
        process.env.REACT_APP_NOTIFICATIONS_PAGE,
        process.env.REACT_APP_CONTACT_PAGE,
        process.env.REACT_APP_FINANCIAL_DOCUMENTS_PAGE,
        process.env.REACT_APP_TECHNICAL_DOCUMENTS_PAGE,
        process.env.REACT_APP_CONTRACTS_PAGE,
        process.env.REACT_APP_SEARCH_PAGE,
        process.env.REACT_APP_ACCOUNT_DETAILS_PAGE,
        process.env.REACT_APP_PROFILE_PAGE,
    ]
    const location = useLocation()

    const findInMenuItems = function(menuItems: MenuItem[], url : string, path: string[]): string[] | undefined {
        for (let item of menuItems) {
            if (item.linkType?.application?.url === url || ('/application/' + item.linkType?.application?.name.replaceAll(' ', '-')) === url) return path
            if (item.menuItems && item.menuItems.length > 0) {
                const subMenuItem = findInMenuItems(item.menuItems, url, path.concat([item.menuTitle]))
                if (subMenuItem) return subMenuItem
            }
        }
        return undefined
    }

    useEffect(() => {
        const pageExist = pages.find(page => page === location.pathname)
        if (pageExist || location.pathname.startsWith('/application/')) {
            const path = data?.menuItems && findInMenuItems(data?.menuItems, location.pathname, [])
            setBreadcrumbPath(path || [])
        } else {
            setBreadcrumbPath([])
        }
    }, [data, location])

    return (
    <section className='breadcrumb'>
      <div className='bc-container bc-width-100'>
        <div className='bc-cols'>
          <div className='bc-col bc-is-12 bc-flex bc-items-center bc-justify-start'>
            {!isDashboard && (
              <>
                <Link
                  to='/'
                  title={t('dashboard.breadcrumb')}
                  className='bc-flex bc-items-center dashboard'
                >
                  {t('dashboard.breadcrumb')}
                </Link>
                <span className="bc-icon-direction-right-fat"></span>
              </>
            )}
              { breadcrumbPath.map((item, index) => (
                  <React.Fragment key={index}>
                    <p>{ item }</p>
                    <span className="bc-icon-direction-right-fat"></span>
                  </React.Fragment>
                  ))}
            <p className={isDashboard ? 'bc-pl-0' : ''}>{breadcrumbTitle}</p>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Breadcrumb
