import './Loading.scss'

function Loading() {
  return (
    <div className='loading-page'>
      <div className="bc-spinner bc-spinner--sm ">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.7525 37.5261C17.0359 37.563 15.3007 37.4154 13.6025 36.9908C11.9228 36.5293 10.28 35.8648 8.76637 34.9603C7.27121 34.0189 5.88681 32.8745 4.66854 31.5639C3.48718 30.2165 2.49041 28.7028 1.69669 27.06C0.939879 25.4172 0.441494 23.6452 0.146155 21.8177C-0.0753493 19.9903 -0.0753493 18.1445 0.183073 16.2986C0.49687 14.4712 1.03217 12.6622 1.84436 10.964C2.69346 9.28429 3.7456 7.69684 5.01925 6.29398C6.36674 4.90958 7.86189 3.69131 9.52317 2.69454C11.1845 1.71623 13.0119 1.0148 14.8947 0.534869C16.7959 0.0918604 18.7525 -0.0742679 20.7276 0.0180256C22.6842 0.165695 24.6593 0.553328 26.5421 1.2363C28.4064 1.95619 30.1785 2.91604 31.8028 4.11586C33.3903 5.35259 34.8301 6.81083 36.0299 8.45365C37.2112 10.1149 38.1526 11.9608 38.854 13.899C39.5186 15.8556 39.8693 17.923 39.9985 19.9903C40.1092 22.0208 38.5587 23.7744 36.5098 23.8851C34.4793 23.9959 32.7258 22.4453 32.615 20.3964C32.615 20.3041 32.615 20.1934 32.615 20.1011V19.9903C32.6519 18.6244 32.5596 17.2585 32.2458 15.911C31.8951 14.5819 31.3783 13.2714 30.6768 12.0531C29.9385 10.8533 29.034 9.74576 28.0003 8.76745C26.9297 7.8076 25.7299 7.01387 24.4378 6.36782C23.1272 5.74022 21.7059 5.33413 20.2661 5.09417C18.8079 4.90958 17.3127 4.89112 15.8176 5.07571C14.3409 5.31567 12.8827 5.74022 11.4983 6.36782C10.1139 7.03233 8.82174 7.88143 7.65885 8.89666C6.53286 9.94881 5.5361 11.1486 4.70545 12.4961C3.93019 13.8436 3.33951 15.3203 2.91496 16.8524C2.54579 18.4029 2.39812 20.0088 2.45349 21.6147C2.56424 23.2206 2.85958 24.8265 3.41334 26.3586C3.98556 27.8907 4.76083 29.3489 5.73914 30.6779C6.75437 31.9885 7.93573 33.1698 9.28321 34.1851C10.6492 35.1634 12.1443 35.9571 13.7502 36.5478C15.3377 37.12 17.0359 37.4153 18.7525 37.5261Z"></path>
        </svg>
      </div>
  </div>
  )
}

export default Loading
