import { useState, useEffect } from 'react';
import { getUser } from '../context/KeycloakContext';

const useLocalStorage = <T = any>(key: string = '', savingData: T | null = null, expiredAfterHours: number = 0) => {
    const user = getUser()
    const user_id = user?.profile.preferred_username || user?.profile.email
    const readStorage = () => {
        const now = new Date()
        const storage = localStorage.getItem(`${key}-${user_id}`)
        const data = storage ? JSON.parse(storage) : null
        if (data && data.hasOwnProperty('expiry')) {
            return now.getTime() < data.expiry || data.expiry === null ? data.value : null
        } else { //backward compatibility
            localStorage.removeItem(`${key}-${user_id}`)
            return data
        }

    }
    const [data, setData] = useState<T | null>(() => readStorage());

    useEffect(() => {
        const now = new Date()
        if (savingData) {
            localStorage.setItem(`${key}-${user_id}`, JSON.stringify({
                value: savingData,
                expiry: expiredAfterHours ? (now.getTime() + expiredAfterHours * 60 * 60 * 1000) : null // Convert hours to milliseconds
            }));
            window.dispatchEvent(new Event(`storage-${key}-${user_id}`));
        }
    }, [savingData]);

    useEffect(() => {
        function loadStoredData() {
            const storedData = readStorage()
            if (storedData) {
                setData(storedData)
            }
        }
        window.addEventListener(`storage-${key}-${user_id}`, loadStoredData)

        return () => {
            window.removeEventListener(`storage-${key}-${user_id}`, loadStoredData)
        }
    }, [])

    return {
        data,
        readStorage
    };
}

export default useLocalStorage;
