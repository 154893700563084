import { Link } from "react-router-dom";

type SecondaryButtonProps = {
    type: 'button' | 'internal' | 'external';
    label: string;
    isFullWidth?: boolean;
    link?: string;
    disabled?: boolean;
    handleAction?: () => void;
};

function SecondaryButton({ type, label, isFullWidth, link, disabled, handleAction }: SecondaryButtonProps) {
    return (
        <>
      {type === 'button' ? (
        <button 
          type="button" 
          className={`bc-btn--secondary ${isFullWidth ? 'bc-btn--full-width' : ''}`} 
          onClick={handleAction}
          disabled={disabled ?? false}
        >
          {label}
        </button>
      ) : type === 'external' ? (
        <a href={link} title={label} rel="noreferrer" target="_blank" className={`bc-btn--secondary ${isFullWidth ? 'bc-btn--full-width' : ''}`} onClick={handleAction}>
          {label}
        </a>
      ) : (
        <Link to={link!!} title={label} className={`bc-btn--secondary ${isFullWidth ? 'bc-btn--full-width' : ''}`} onClick={handleAction}>
          {label}
        </Link>
      )}
    </>
    )
}

export default SecondaryButton
