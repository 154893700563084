import { useTranslation } from 'react-i18next';
import { baseFontSize } from '../../../utils/constants';

interface WidgetProps {
  title: string;
  children: JSX.Element;
  isSkeleton: boolean;
  height: number
}

function Widget({title, children, isSkeleton, height}: WidgetProps) {
  const { t } = useTranslation();
  
  const widgetClassnames = `
    widget
    bc-shadow--box
    ${isSkeleton ? 'bc-skeleton' : ''}
    ${!isSkeleton && title === t('dashboard.widgets.frequently_visited.title') ? 'custom-skeleton' : ''}
    ${title === t('dashboard.widgets.users_overview.title') ? 'users-overview' : ''}
  `;

  return (
      <article 
        className={widgetClassnames} 
        style={isSkeleton ? { height: `${height / baseFontSize}rem` } : undefined}
      > 
        {!isSkeleton && (
          <>
            <div className='widget-title bc-width-100'>
              <h2>{title}</h2>
            </div>
            {children}
          </>
        )}
      </article>
    )
  }
  
  export default Widget
  
